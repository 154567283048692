import React from 'react'

import { Layout } from './layout'
import NotFoundPage from '../pages/404'
import { usePreview } from '../hooks'
import FullPodcast from './fullPodcast'
import { LandingPage } from './landingPage'
import { Content } from '../types';

type LandingPreviewPageProps = {
  params: {
    "*": string;
  }
}

const LandingPreviewPage: React.FC<LandingPreviewPageProps> = (props) => {
  const { params } = props
  const { loading, error, data } = usePreview<Content>(params['*'])

  if (!data && !loading) return <NotFoundPage />
  return (
    <LandingPage content={data && data.getContentPreview} pendingMessage={error && error.message} />
  )
}

export default LandingPreviewPage
